import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import testKits, {
  multiplexTestKits,
  testKitsPrimary,
  testKitsSecondary,
} from "./partials/testKits.js";
import { useThemeContext } from "./theme";
import { useNavigate, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AVAILABLE_TEST_TARGETS = ["covid", "fluA", "fluB"];
const POSSIBLE_POSITIVE_VALUES = ["positive", "true", "yes", "1"];
const POSSIBLE_NEGATIVE_VALUES = ["negative", "false", "no", "0"];
const POSTIVE_VALUE = "Positive";
const NEGATIVE_VALUE = "Negative";

export const Steps = {
  Step01: "Step01",
  Step02: "Step02",
  Step03: "Step03",
  Step03FollowUp: "Step03FollowUp",
  Step04: "Step04",
  Step05: "Step05",
  Step06: "Step06",
  Final: "Final",
};
export const StepOrder = [
  Steps.Step01,
  Steps.Step02,
  Steps.Step03,
  Steps.Step03FollowUp,
  Steps.Step04,
  Steps.Step05,
  Steps.Step06,
  Steps.Final,
];
export const DisableHistoryBackSteps = [
  Steps.Step05,
  Steps.Step06,
  Steps.Final,
];

/** Utility function that checks the store state and returns the step that should be active */
export const determineActiveStep = (nextStep, storeState = {}) => {
  const { tests = [], testType } = storeState;
  let derivedStep = nextStep;
  // If test targets have been provided but not results, go to step 2
  if (derivedStep === Steps.Step01 && tests.length) {
    derivedStep = Steps.Step02;
  }
  // If we have results, but not a test type, go to step 3 (very unlikely)
  if (
    derivedStep === Steps.Step02 &&
    tests.length &&
    tests.every((test) => test.testResult)
  ) {
    derivedStep = Steps.Step03;
  }
  // If all are provided, skip to step 4 (submit step)
  if (
    derivedStep === Steps.Step03 &&
    tests.length &&
    tests.every((test) => test.testResult) &&
    testType
  ) {
    derivedStep = Steps.Step04;
  }
  // If the derived step has been updated based on the store state, check again
  return derivedStep;
};

const StoreContext = createContext({});

/**
 * Generate the initial state of the store via the URL query parameters
 */
export const getInitialStoreState = (search, themeContent = {}) => {
  const { themeTestTypes = [] } = themeContent;
  let initialTests = [];
  let initialTestType = null;
  const urlTestType = search.get("testType");
  const urlTestTargets = search.get("testTargets");
  const urlTestTargetsList = urlTestTargets ? urlTestTargets.split(",") : [];

  const multiplexThemeTestTypes = multiplexTestKits.filter((r) =>
    themeTestTypes.includes(r)
  );

  const singleThemeTestTypes = [
    ...testKitsPrimary,
    ...testKitsSecondary,
  ].filter((r) => themeTestTypes.includes(r));

  // Only auto-select the test type if we have a test type provided in the url or we have a single test
  // category available for the theme (e.g. only covid tests available)
  if (
    urlTestType ||
    (singleThemeTestTypes.length && !multiplexThemeTestTypes.length) ||
    (multiplexThemeTestTypes.length && !singleThemeTestTypes.length)
  ) {
    if (
      multiplexTestKits.includes(urlTestType) ||
      (multiplexThemeTestTypes.length && themeTestTypes.length === 1)
    ) {
      initialTests = [
        { testTarget: "covid" },
        { testTarget: "fluA" },
        { testTarget: "fluB" },
      ];
      if (urlTestType) {
        initialTestType = urlTestType;
      } else if (
        multiplexThemeTestTypes.length === 1 &&
        multiplexThemeTestTypes.length &&
        themeTestTypes.length === 1
      ) {
        initialTestType = multiplexThemeTestTypes[0];
      }
    } else if (
      testKitsPrimary.includes(urlTestType) ||
      testKitsSecondary.includes(urlTestType) ||
      singleThemeTestTypes.length
    ) {
      initialTests = [{ testTarget: "covid" }];
      if (urlTestType) {
        initialTestType = urlTestType;
      } else if (
        singleThemeTestTypes.length === 1 &&
        singleThemeTestTypes.length &&
        themeTestTypes.length === 1
      ) {
        initialTestType = singleThemeTestTypes[0];
      }
    } else {
      console.error(`Invalid test type provided: ${urlTestType}`);
    }
    // Only use the test target query parameter if the test type is not specified
  } else if (urlTestTargets) {
    urlTestTargetsList.forEach((testTarget) => {
      // Check that the test target is available
      if (AVAILABLE_TEST_TARGETS.includes(testTarget)) {
        // If we already have the test target provided, don't add it again
        if (initialTests.some((test) => test.testTarget === testTarget)) {
          console.error(`Test target, ${testTarget}, provided twice`);
        } else {
          initialTests.push({ testTarget });
        }
      } else {
        console.error(`Invalid test target provided: ${testTarget}`);
      }
    });
  }

  if (
    initialTestType &&
    urlTestTargets &&
    urlTestTargetsList.length !== initialTests.length
  ) {
    console.error(
      `Invalid number of test targets provided, the number of test targets and test results should match the test type.`
    );
  }

  const urlTestResults = (search.get("testResults") || "").split(",");
  // Only assign tests results if we have the same number of results as tests
  if (urlTestTargets && urlTestTargetsList.length === urlTestResults.length) {
    // Giving some options for the test results
    initialTests.forEach((test, index) => {
      if (!urlTestResults[index]) {
        return;
      }
      const testResult = urlTestResults[index].toLowerCase();
      if (POSSIBLE_POSITIVE_VALUES.includes(testResult)) {
        test.testResult = POSTIVE_VALUE;
      } else if (POSSIBLE_NEGATIVE_VALUES.includes(testResult)) {
        test.testResult = NEGATIVE_VALUE;
      } else {
        console.error(`Invalid test result value provided: ${testResult}`);
      }
    });
  } else if (
    urlTestTargets &&
    urlTestTargetsList.length !== urlTestResults.length
  ) {
    console.error(
      `Invalid number of test results provided, the number of test targets and test results should be the same.`
    );
  }

  // Skip steps if we have provided test information
  let initialStep = determineActiveStep("Step01", {
    tests: initialTests,
    testType: initialTestType,
  });

  let initialDiagnosticType = null;
  if (initialTestType) {
    initialDiagnosticType = testKits[initialTestType].diagnosticType;
  }

  return {
    tests: initialTests,
    testType: initialTestType,
    activeStep: initialStep,
    furthestStepReached: initialStep,
    diagnosticType: initialDiagnosticType,
  };
};

const search = new URLSearchParams(window.location.search);

export function StoreContextProvider(props) {
  const navigate = useNavigate();
  const themeContent = useThemeContext();
  const { brand, isWhiteLabeled } = themeContent;
  const { i18n } = useTranslation();

  const initialState = getInitialStoreState(search, themeContent);
  const [tests, setTests] = useState(initialState.tests);
  const [testType, setTestTypeFn] = useState(initialState.testType);
  const [diagnosticType, setDiagnosticType] = useState(
    initialState.diagnosticType
  );
  const [furthestStepReached, setFurthestStepReached] = useState(
    initialState.furthestStepReached
  );
  const [activeStep, setActiveStepFn] = useState(initialState.activeStep);
  const [resultKey, setResultKey] = useState(null);

  // Wrapper function that sets the diagnostic type from the test type
  const setTestType = (testType) => {
    setTestTypeFn(testType);
    setDiagnosticType(testKits[testType].diagnosticType);
  };

  // Wrapper function that skips steps if provided information is present
  const setActiveStep = (nextStep, storeState = {}, shouldNavigate = true) => {
    let newActiveStep = nextStep;
    const activeStepIndex = StepOrder.indexOf(activeStep);
    const nextStepIndex = StepOrder.indexOf(nextStep);
    const isAdvancing = nextStepIndex > activeStepIndex;
    const isBackBlocked = DisableHistoryBackSteps.includes(activeStep);

    // If the current step does not allow going backwards, or doesnt exist, stop the move
    if ((!isAdvancing && isBackBlocked) || nextStepIndex < 0) {
      return;
    }

    // If going forward, check if we should skip steps
    // If going backwards, just go to the step
    if (StepOrder.indexOf(nextStep) > StepOrder.indexOf(activeStep)) {
      // If we haven't advanced past this step, determine the step we should go to
      if (
        StepOrder.indexOf(nextStep) > StepOrder.indexOf(furthestStepReached)
      ) {
        newActiveStep = determineActiveStep(
          nextStep,
          storeState || {
            tests: tests,
            testType: testType,
          }
        );
      }
    }
    setActiveStepFn(newActiveStep);
    // If we have reached a step further than the furthest step reached, update the furthest step reached
    if (
      StepOrder.indexOf(newActiveStep) > StepOrder.indexOf(furthestStepReached)
    ) {
      setFurthestStepReached(newActiveStep);
    }

    if (shouldNavigate) {
      // TODO: I don't love how opinionated this is, but it's the only way to be explicit and avoid open redirects
      const path =
        generatePath("/:brand?/:language?/", {
          brand: isWhiteLabeled && brand ? brand : undefined,
          language: i18n.resolvedLanguage,
        }) + `#${nextStep}`;

      // Trigger the movement to the next step via navigation
      navigate(path);
      document.title = `MakeMyTestCount - ${nextStep}`;
    }
    // Scroll to the top of the page
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  const store = {
    tests,
    setTests,
    testType,
    setTestType,
    activeStep,
    setActiveStep,
    resultKey,
    setResultKey,
    furthestStepReached,
    diagnosticType,
  };

  return (
    <StoreContext.Provider value={store}>
      {props.children}
    </StoreContext.Provider>
  );
}

StoreContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useStoreContext = () => React.useContext(StoreContext);
