import React from "react";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";
import "./PluginUpdates.css";

const PluginUpdates = () => {
  return (
    <>
      <Nav />
      <main className="plugin-updates">
        <h1>MMTC Plugin Updates</h1>
        <h2 id="1.1.0">Version 1.1.0, Aug-2023</h2>
        <div className="plugin-update-content">
          <p>
            Users of MMTC can pre-select and skip steps of the reporting flow by specifying query parameters or data attributes on the plugin:
          </p>
          <h4>Test Type</h4>
          <p>
            Specify the brand of the test, skpping step 3. This also selects the relevant Test Targets (see below).
          </p>
          <code>
            [url format]
            <br />
            https://report.makemytestcount.org?testType=iHealth
            <br />
            <br />
            [plugin format]
            <br />
            &lt;button data-open-mmtc-dialog
            data-mmtc-test-type=&quot;iHealth&quot;&gt; Open &lt;/button&gt;
          </code>
          <h4>Test Targets</h4>
          <p>
            Specify the test targets/analytes in a comma-separated list. Currently
            supported:{" "}
            <code>&quot;covid&quot;, &quot;fluA&quot;, &quot;fluB&quot;</code>
          </p>
          <code>
            [url format]
            <br />
            https://report.makemytestcount.org?testTargets=covid,fluA,fluB
            <br />
            <br />
            [plugin format]
            <br />
            &lt;button data-open-mmtc-dialog
            data-mmtc-test-targets=&quot;covid,fluA,fluB&quot; &gt; Open
            &lt;/button&gt;
          </code>
          <h4>Test Results</h4>
          <p>
            Specify the test results in a comma-separated list. Requires
            the use of <code>testTargets</code>, and all test results must be in
            the order of the the targets in the other query parameter.
            Acceptable values for positive
            <br />
            Positive Values options:
            <br />
            <code>
              &quot;positive&quot;, &quot;true&quot;, &quot;yes&quot;,
              &quot;1&quot;
            </code>
            <br />
            Negative Values options:
            <br />
            <code>
              &quot;negative&quot;, &quot;false&quot;, &quot;no&quot;,
              &quot;0&quot;{" "}
            </code>
          </p>
          <code>
            [url format]
            <br />
            https://report.makemytestcount.org?testTargets=covid,fluA,fluB&testResults=positive,negative,positive
            <br />
            <br />
            [plugin format]
            <br />
            &lt;button data-open-mmtc-dialog
            data-mmtc-test-results=&quot;positive,negative,positive&quot;
            data-mmtc-test-targets=&quot;covid,fluA,fluB&quot; &gt; Open
            &lt;/button&gt;
          </code>
        </div>

        <hr />

        <h2 id="1.0.0">Version 1.0.0, Jan-2023</h2>
        <div className="plugin-update-content">
          <ul>
            <li>Initial release</li>
          </ul>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PluginUpdates;
