import React from "react";
import { t } from "i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";

import hero_img from "../../../images/hero_img.png";
import { useStoreContext, Steps } from "../../../Store";
import { useThemeContext } from "../../../theme";
import { useRememberMe } from "../../../rememberMe";
import useTestJournal from "../../TestJournal";

export const Step01 = () => {
  const theme = useThemeContext();
  const { testingHistory } = useRememberMe();
  const { TestJournalContent } = useTestJournal();
  const { tests, setTests, setActiveStep } = useStoreContext();

  const handleSetTestType = (testType) => {
    if (testType === "flu-and-covid") {
      setTests([
        { testTarget: "covid" },
        { testTarget: "fluA" },
        { testTarget: "fluB" },
      ]);
    } else {
      setTests([{ testTarget: "covid" }]);
    }
    window.dataLayer.push({
      event: "interactionEvent",
      event_name: "test_type_select",
      result: testType,
    });
    setActiveStep(Steps.Step02, { tests: tests, testType });
  };

  const handleTestTarget = (event) => {
    handleSetTestType(event.target.value);
  };
  const handleCovidOnKeyDown = (event) => {
    if (["Down", "ArrowDown", "Right", "ArrowRight"].includes(event.key)) {
      document.querySelector("#covid-only").focus();
    }
  };
  const handleFluAndCovidOnKeyDown = (event) => {
    if (["Up", "ArrowUp", "Left", "ArrowLeft"].includes(event.key)) {
      document.querySelector("#flu-and-covid").focus();
    }
  };

  return (
    <>
      <section className="wizard-step-wrapper testTarget">
        <div className="highlighted" id="about-banner">
          <h1
            dangerouslySetInnerHTML={{ __html: t("s1-about-banner-01") }}
          ></h1>
          <div className="family-annotation">
            <em>{t("s1-about-banner-02")}</em>
          </div>
        </div>
        <div className="col-2-md container">
          <div className="section-image">
            <img
              className="hero_img"
              src={
                theme.themeLogoUrl && theme.isEmbedded
                  ? theme.themeLogoUrl
                  : hero_img
              }
              alt={theme.themeName ? theme.themeName : t("s1-hero-h")}
            />
          </div>
          <div className="section-text">
            <fieldset>
              <legend className="big-label" id="s1-hero-h">
                <span
                  dangerouslySetInnerHTML={{ __html: t("s1-hero-h") }}
                ></span>
              </legend>
              <div role="radiogroup" aria-labelledby="s1-hero-h">
                <div className="input-button">
                  <button
                    type="button"
                    id="covid-only"
                    name="test-result"
                    value="covid-only"
                    role="radio"
                    aria-checked={tests.length === 1}
                    aria-keyshortcuts="Space Enter"
                    aria-label={t("s1-option-1")}
                    onClick={handleTestTarget}
                    onKeyDown={handleCovidOnKeyDown}
                    className="button primary"
                  >
                    {t("s1-option-1")}
                  </button>
                </div>
                <div className="input-button">
                  <button
                    type="button"
                    id="flu-and-covid"
                    name="test-result"
                    value="flu-and-covid"
                    role="radio"
                    aria-checked={tests.length > 1}
                    aria-keyshortcuts="Space Enter"
                    aria-label={t("s1-option-2")}
                    onClick={handleTestTarget}
                    onKeyDown={handleFluAndCovidOnKeyDown}
                    className="button"
                  >
                    {t("s1-option-2")}
                  </button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        {testingHistory.length ? (
          <div className="test-journal-banner highlighted">
            <div className="container">
              <PDFDownloadLink
                className="button test-journal-button"
                fileName="test-results.pdf"
                document={<TestJournalContent />}
              >
                {t("download-journal")}
              </PDFDownloadLink>
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
};
