import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import Nav from "../partials/nav";
import Attention from "../partials/attention";
import Footer from "../partials/footer";
import { useThemeContext } from "../theme";
import Wizard from "../partials/wizard/wizard";

export default function Home() {
  const { t } = useTranslation();
  const theme = useThemeContext();
  const tagManagerArgs = {
    dataLayer: {
      event: "page_view",
      page_location: document.location.href,
      page_title: t("home-meta-title"),
    },
  };

  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    document.title = t("home-meta-title");

    const reCaptchaScritpBlock = document.createElement("script");
    reCaptchaScritpBlock.id = "recaptcha-script";
    reCaptchaScritpBlock.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    reCaptchaScritpBlock.async = true;
    document.head.appendChild(reCaptchaScritpBlock);
  }, [t]);

  return (
    <>
      {theme.isEmbedded ? null : <Nav />}
      <main className="home-page">
        <form>
          <Wizard />
        </form>
        <Attention />
      </main>
      {theme.isEmbedded ? null : <Footer />}
    </>
  );
}
