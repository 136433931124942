import React, { useState, useRef } from "react";
import { without, uniq } from "lodash";
import { paramCase } from "change-case";
import propTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const urlRegex =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

function Form({ onSubmit, isSubmitting }) {
  const [brand, setBrand] = useState("");
  const [comment, setComment] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [logoImageUrl, setLogoImageUrl] = useState(null);
  const [origin, setOrigin] = useState("");
  const [themeName, setThemeName] = useState("");
  const [themeHomeUrl, setThemeHomeUrl] = useState("");
  const [themeNegUrl, setThemeNegUrl] = useState("");
  const [themePosUrl, setThemePosUrl] = useState("");
  const [themeGeneralUrl, setThemeGeneralUrl] = useState("");
  const [fieldErrors, setFieldErrors] = useState([]);

  const logoInputRef = useRef(null);

  const handleThemeNameChange = (event) => {
    setThemeName(event.target.value);
    const generatedBrand = paramCase(event.target.value);
    setBrand(generatedBrand);

    if (event.target.value || fieldErrors.include["themeName"]) {
      setFieldErrors(without(fieldErrors, "themeName"));
    }
  };

  const handleOriginChange = (event) => {
    const url = event.target.value;
    if (event.type === "change" || urlRegex.test(url)) {
      setOrigin(event.target.value);
      setFieldErrors(without(fieldErrors, "origin"));
    } else {
      setFieldErrors(uniq([...fieldErrors, "origin"]));
    }
  };
  const handleThemeHomeUrlChange = (event) => {
    const url = event.target.value;
    if (!url || event.type === "change" || urlRegex.test(url)) {
      setThemeHomeUrl(event.target.value);
      setFieldErrors(without(fieldErrors, "themeHomeUrl"));
    } else {
      setFieldErrors(uniq([...fieldErrors, "themeHomeUrl"]));
    }
  };
  const handleThemePosUrlChange = (event) => {
    const url = event.target.value;
    if (!url || event.type === "change" || urlRegex.test(url)) {
      setThemePosUrl(event.target.value);
      setFieldErrors(without(fieldErrors, "themePosUrl"));
    } else {
      setFieldErrors(uniq([...fieldErrors, "themePosUrl"]));
    }
  };
  const handleThemeNegUrlChange = (event) => {
    const url = event.target.value;
    if (!url || event.type === "change" || urlRegex.test(url)) {
      setThemeNegUrl(event.target.value);
      setFieldErrors(without(fieldErrors, "themeNegUrl"));
    } else {
      setFieldErrors(uniq([...fieldErrors, "themeNegUrl"]));
    }
  };
  const handleThemeGeneralUrlChange = (event) => {
    const url = event.target.value;
    if (!url || event.type === "change" || urlRegex.test(url)) {
      setThemeGeneralUrl(event.target.value);
      setFieldErrors(without(fieldErrors, "themeGeneralUrl"));
    } else {
      setFieldErrors(uniq([...fieldErrors, "themeGeneralUrl"]));
    }
  };

  const handleBrandChange = (event) => {
    setBrand(event.target.value);

    if (event.target.value || fieldErrors.include["Brand"]) {
      setFieldErrors(without(fieldErrors, "themeName"));
    }
  };

  const handleLogoChange = (event) => {
    // ~5mb
    if (event.target.files[0].size > 5242880) {
      setFieldErrors(uniq([...fieldErrors, "logoImage"]));
      handleLogoClear();
      return;
    }

    setFieldErrors(without(fieldErrors, "logoImage"));
    setLogoImage(event.target.files[0]);

    // Convert the image to a data URL
    const reader = new FileReader();
    reader.onload = (event) => {
      setLogoImageUrl(event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleLogoClear = () => {
    setLogoImageUrl(null);
    setLogoImage(null);
    logoInputRef.current.value = null;
  };

  const handleEmailChange = (event) => {
    setContactEmail(event.target.value);

    if (event.target.value || fieldErrors.include["contactEmail"]) {
      setFieldErrors(without(fieldErrors, "contactEmail"));
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newFieldErrors = [];
    if (!themeName) {
      newFieldErrors.push("themeName");
    }
    if (!origin) {
      newFieldErrors.push("origin");
    }
    if (!contactEmail) {
      newFieldErrors.push("contactEmail");
    }

    setFieldErrors(uniq([...fieldErrors, ...newFieldErrors]));
    if (fieldErrors.length) {
      return;
    }

    onSubmit({
      brand,
      origin,
      logoImage,
      logoImageUrl,
      themeName,
      themeHomeUrl,
      themePosUrl,
      themeNegUrl,
      themeGeneralUrl,
      contactEmail,
      comment,
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      id="partner-request-form"
      disabled={isSubmitting}
    >
      <fieldset>
        <legend>
          <small>* Required Field</small>
        </legend>
        <br />
        <div
          className={`form-group ${
            fieldErrors.includes("themeName") ? "error" : ""
          }`}
        >
          <label htmlFor="theme-name">
            <strong>Organization Name*</strong>
          </label>
          <input
            type="text"
            id="theme-name"
            value={themeName}
            onChange={handleThemeNameChange}
            placeholder="ex. ABC County Health Department"
            disabled={isSubmitting}
            required
          />
          <input
            type="text"
            hidden
            id="theme-brand"
            value={brand}
            onChange={handleBrandChange}
            disabled={isSubmitting}
          />
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("origin") ? "error" : ""
          }`}
        >
          <label htmlFor="origin">
            <strong>Site Origin*</strong> - Used to record where users come
            from. If using the JavaScript Plugin, this must be the same as the
            site where the it will be installed on.
          </label>
          <input
            type="text"
            id="origin"
            value={origin}
            onChange={handleOriginChange}
            onBlur={handleOriginChange}
            placeholder="https://mysite.com"
            disabled={isSubmitting}
            required
          />
          {fieldErrors.includes("origin") ? (
            <p className="error">Please enter a valid url</p>
          ) : null}
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("themeHomeUrl") ? "error" : ""
          }`}
        >
          <label htmlFor="themeHomeUrl">
            <strong>Home Link</strong> (white-labeled site only) - If defined,
            users who click on the logo in the top left of your white-labeled
            site will be directed to this URL.
          </label>
          <input
            type="text"
            id="themeHomeUrl"
            value={themeHomeUrl}
            onChange={handleThemeHomeUrlChange}
            onBlur={handleThemeHomeUrlChange}
            placeholder="mysite.com"
            disabled={isSubmitting}
          />
          {fieldErrors.includes("themeHomeUrl") ? (
            <p className="error">Please enter a valid url</p>
          ) : null}
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("themePosUrl") ? "error" : ""
          }`}
        >
          <label htmlFor="themePosUrl">
            <strong>Positive Test Link</strong> - If the user has tested
            positive, where can they find additional instructions?
          </label>
          <input
            type="text"
            id="themePosUrl"
            value={themePosUrl}
            onChange={handleThemePosUrlChange}
            onBlur={handleThemePosUrlChange}
            placeholder="mysite.com/positive-instructions.html"
            disabled={isSubmitting}
          />
          {fieldErrors.includes("themePosUrl") ? (
            <p className="error">Please enter a valid url</p>
          ) : null}
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("themeNegUrl") ? "error" : ""
          }`}
        >
          <label htmlFor="themeNegUrl">
            <strong>Negative Test Link</strong> - If the user has tested
            negative, where can they find additional instructions?
          </label>
          <input
            type="text"
            id="themeNegUrl"
            value={themeNegUrl}
            onChange={handleThemeNegUrlChange}
            onBlur={handleThemeNegUrlChange}
            placeholder="mysite.com/negative-instructions.html"
            disabled={isSubmitting}
          />
          {fieldErrors.includes("themeNegUrl") ? (
            <p className="error">Please enter a valid url</p>
          ) : null}
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("themeGeneralUrl") ? "error" : ""
          }`}
        >
          <label htmlFor="themeGeneralUrl">
            <strong>General Information Link</strong> - Where can the user find
            additional information?
          </label>
          <input
            type="text"
            id="themeGeneralUrl"
            value={themeGeneralUrl}
            onChange={handleThemeGeneralUrlChange}
            onBlur={handleThemeGeneralUrlChange}
            placeholder="mysite.com/instructions.html"
            disabled={isSubmitting}
          />
          {fieldErrors.includes("themeGeneralUrl") ? (
            <p className="error">Please enter a valid url</p>
          ) : null}
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("logoImage") ? "error" : ""
          }`}
        >
          <label className="logo-label" htmlFor="theme-logo">
            <strong>Logo</strong>{" "}
            <small>{`${
              fieldErrors.includes("logoImage") ? "File is too large: " : ""
            }Maximum file size: 5MB`}</small>{" "}
          </label>
          <input
            type="file"
            id="theme-logo"
            onChange={handleLogoChange}
            accept="image/*"
            ref={logoInputRef}
            disabled={isSubmitting}
          />
          {logoImageUrl ? (
            <div className="logo-image-container">
              <img
                src={logoImageUrl}
                alt="Uploaded Logo"
                className="logo-image-preview"
              />
              <button
                className="logo-image-delete"
                onClick={handleLogoClear}
                disabled={isSubmitting}
                title="Remove Logo"
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          ) : null}
        </div>
      </fieldset>
      <hr />
      <br />
      <fieldset>
        <legend style={{ marginBottom: "1rem" }}>
          <strong>Who should we contact when your site is ready?</strong>
        </legend>
        <div
          className={`form-group ${
            fieldErrors.includes("contactEmail") ? "error" : ""
          }`}
        >
          <label htmlFor="contactEmail">Email*</label>
          <input
            type="email"
            id="contactEmail"
            value={contactEmail}
            onChange={handleEmailChange}
            disabled={isSubmitting}
            required
          />
          {fieldErrors.includes("contactEmail") ? (
            <p className="error">Please enter a valid email address</p>
          ) : null}
        </div>
        <div className={`form-group`}>
          <label htmlFor="comment">Additional information:</label>
          <textarea
            id="comment"
            value={comment}
            onChange={handleCommentChange}
            disabled={isSubmitting}
          />
        </div>
      </fieldset>
      <br />
      <div className="form-submit-wrapper">
        {fieldErrors.length ? (
          <p className="error">Please correct the errors above</p>
        ) : null}
        <button
          type="submit"
          className={`submit-button ${
            fieldErrors.length ? "button-error" : ""
          }`}
          disabled={isSubmitting}
        >
          {"Submit"}
        </button>
      </div>
    </form>
  );
}

Form.propTypes = {
  onSubmit: propTypes.func,
  isSubmitting: propTypes.bool,
};

export default Form;
