import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate, generatePath } from "react-router-dom";

import { languages } from "../i18n/config.js";
import languageNaming from "../i18n/languageNaming.js";
import { useThemeContext } from "../theme.js";

const LanguageSwitcher = ({ stacked }) => {
  const { i18n } = useTranslation();

  const theme = useThemeContext();
  const navigate = useNavigate();
  // If none specified, return english
  const locale = i18n.resolvedLanguage;
  const nextLanguage = locale === "en" ? "es" : "en";

  const handleTranslation = async () => {
    if (nextLanguage !== i18n.resolvedLanguage) {
      await i18n.changeLanguage(nextLanguage);
      const path = generatePath("/:brand?/:locale?", {
        locale: nextLanguage,
        brand: theme.brand,
      });
      navigate(`${path}${window.location.search}${window.location.hash}`);
    }
  };

  // Next Langauge Name in english (key of the languageNaming object)
  const nextLanguageName = Object.keys(languages).find(
    (key) => languages[key] === nextLanguage
  );
  const nextLanguageLocaleName = languageNaming[nextLanguageName];

  return (
    <ul className={`language-switcher ${stacked ? "stacked" : ""}`}>
      <li>
        <button className="link-button" onClick={handleTranslation}>
          <i className="fas fa-globe" aria-label="Change Language" />
          {nextLanguageLocaleName}
        </button>
      </li>
    </ul>
  );
};

LanguageSwitcher.propTypes = {
  stacked: PropTypes.bool,
};

export default LanguageSwitcher;
