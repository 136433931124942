import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { useThemeContext } from "../../../theme";
import { useStoreContext, Steps } from "../../../Store";
import {
  multiplexTestKits,
  testKitsPrimary,
  testKitsSecondary,
} from "../../testKits.js";

const TestResultOption = ({
  label,
  testTarget,
  testResult,
  onSetTestResult,
}) => {
  const handleTestResultPositive = () => {
    onSetTestResult({
      testTarget,
      testResult: "Positive",
    });
  };
  const handleTestResultNegative = () => {
    onSetTestResult({
      testTarget,
      testResult: "Negative",
    });
  };
  const handlePositiveOnKeyDown = (event) => {
    if (["Down", "ArrowDown", "Right", "ArrowRight"].includes(event.key)) {
      document.querySelector(`#test-result-${testTarget}-negative`).focus();
    }
  };
  const handleNegativeOnKeyDown = (event) => {
    if (["Up", "ArrowUp", "Left", "ArrowLeft"].includes(event.key)) {
      document.querySelector(`#test-result-${testTarget}-positive`).focus();
    }
  };

  return (
    <fieldset className={`test-result test-target-${testTarget}`}>
      <legend className="big-label" id={`s1-${testTarget}-h`}>
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
      </legend>
      <div
        className="test_results_buttons"
        role="radiogroup"
        aria-labelledby={`s1-${testTarget}-h`}
      >
        <div className="input-button">
          <button
            type="button"
            id={`test-result-${testTarget}-positive`}
            name="test-result"
            value="Positive"
            role="radio"
            aria-checked={testResult === "Positive"}
            aria-keyshortcuts="Space Enter"
            aria-label={t("positive")}
            onClick={handleTestResultPositive}
            onKeyDown={handlePositiveOnKeyDown}
            className={`button ${testResult === "Positive" ? "active" : ""} ${
              testResult === "Negative" ? "inactive" : ""
            }`}
          >
            <FontAwesomeIcon icon={faPlus} alt={t("positive")} />
            {t("positive")}
          </button>
        </div>
        <div className="input-button">
          <button
            type="button"
            id={`test-result-${testTarget}-negative`}
            name="test-result"
            value="Negative"
            role="radio"
            aria-checked={testResult === "Negative"}
            aria-keyshortcuts="Space Enter"
            aria-label={t("negative")}
            onClick={handleTestResultNegative}
            onKeyDown={handleNegativeOnKeyDown}
            className={`button ${testResult === "Negative" ? "active" : ""} ${
              testResult === "Positive" ? "inactive" : ""
            }`}
          >
            <FontAwesomeIcon icon={faMinus} alt={t("negative")} />
            {t("negative")}
          </button>
        </div>
      </div>
    </fieldset>
  );
};

TestResultOption.propTypes = {
  label: PropTypes.string,
  testTarget: PropTypes.string,
  testResult: PropTypes.string,
  onSetTestResult: PropTypes.func,
};

export const Step02 = () => {
  const { tests, testType, setTests, setActiveStep } = useStoreContext();
  const theme = useThemeContext();
  const handleSetTestResult = (testResult) => {
    const newTests = tests.map((test) => {
      if (test.testTarget === testResult.testTarget) {
        return testResult;
      }
      return test;
    });

    setTests(newTests);

    // If all of the tests have been answered, go to the next step
    if (newTests.every((test) => test.testResult)) {
      window.dataLayer.push({
        event: "interactionEvent",
        event_name: "test_result_select",
        test_result: testResult ? "exists" : undefined,
      });

      setActiveStep(Steps.Step03, {
        tests: newTests,
        testType: testType,
      });
    }
  };

  const handleBackButton = () => {
    setActiveStep(Steps.Step01);
  };

  let shouldShowBackButton = true;
  // If the theme has test types, and the test types includes both singular and multiplex tests, allow the user to go back to the test type selection
  if (theme.themeTestTypes?.length) {
    if (
      theme.themeTestTypes.some((testType) =>
        multiplexTestKits.includes(testType)
      ) &&
      (theme.themeTestTypes.some((testType) =>
        testKitsPrimary.includes(testType)
      ) ||
        theme.themeTestTypes.some((testType) =>
          testKitsSecondary.includes(testType)
        ))
    ) {
      shouldShowBackButton = true;
    } else {
      shouldShowBackButton = false;
    }
  }

  return (
    <>
      <section className="wizard-step-wrapper testResult">
        {shouldShowBackButton ? (
          <button
            className="back-button"
            onClick={handleBackButton}
            type="button"
            aria-label="Back to Step 1"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        ) : (
          <div className="highlighted" id="about-banner">
            <h1 dangerouslySetInnerHTML={{ __html: t("s2-about-banner") }}></h1>
          </div>
        )}
        <div className="col-2-md container test-result-options">
          {tests.map((test) => (
            <TestResultOption
              key={test.testTarget}
              label={t(`s2-${test.testTarget}`)}
              testTarget={test.testTarget}
              testResult={test.testResult}
              onSetTestResult={handleSetTestResult}
            />
          ))}
        </div>
      </section>
    </>
  );
};
