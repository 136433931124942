import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import "./i18n/config";
import App from "./App";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Always reset the page to the top (any time the page is loaded or reloaded)
window.onload = () => {
  window.scrollTo(0,0);
};
