import React from "react";
import { useTranslation } from "react-i18next";
import {
  Document,
  Page,
  Text,
  Font,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

import TestingJournalPDF from "./TestingJournalPDF";
import bug from "../images/mmtc-logo@2x.png";
import nibib from "../images/NIH_NIBIB_Master_Logo_Blue.png";
import ce from "../images/ce-logo-blue@2x.png";
import NotoSansReg from "../fonts/NotoSans-Regular.ttf";
import NotoSansBold from "../fonts/NotoSans-Bold.ttf";
import NotoSansSemiBold from "../fonts/NotoSans-SemiBold.ttf";
import { useRememberMe } from "../rememberMe";
import { normalizePhoneInput } from "./NormalizedPhoneInput";
import BitterReg from "../fonts/Bitter-Regular.ttf";
import BitterSemiBold from "../fonts/Bitter-SemiBold.ttf";
import "./TestJournal.css";

Font.register({
  family: "Noto Sans",
  fonts: [
    {
      src: NotoSansReg,
      fontWeight: "400",
      fontStyle: "normal",
    },
    {
      src: NotoSansSemiBold,
      fontWeight: "500",
      fontStyle: "normal",
    },
    {
      src: NotoSansBold,
      fontWeight: "600",
      fontStyle: "normal",
    },
  ],
});

Font.register({
  family: "Bitter",
  fonts: [
    {
      src: BitterReg,
      fontWeight: "400",
      fontStyle: "normal",
    },
    {
      src: BitterSemiBold,
      fontWeight: "500",
      fontStyle: "normal",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Noto Sans",
  },

  testingJournalPage: {
    padding: "0 50px",
    display: "block",
  },

  testingJournalHeader: {
    padding: "17px",
    borderBottom: "1px solid #d9d9d9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 0 17px",
    width: "100%",
  },

  headerImageContainer: {
    width: "105px",
    height: "45px",
    display: "block",
  },

  testingJournalHeaderImg: {
    width: "auto",
    height: "auto",
  },

  testingJournalHeaderH4: {
    margin: "10px",
    fontFamily: "Bitter",
    fontWeight: "500",
    fontSize: "12px",
    color: "#00426b",
  },

  testingJournalPageH2: {
    alignItems: "center",
  },

  testingJournalPageMain: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
    width: "100%",
    gap: "20px",
  },

  strong: {
    fontWeight: "600",
    fontFamily: "Noto Sans",
  },

  contactInfo: {
    backgroundColor: "#eeeeee",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    color: "#00426b",
    fontSize: "14px",
  },

  address: {
    margin: "0 0 10px",
    display: "flex",
    flexDirection: "column",
  },

  contactText: {
    fontWeight: "400",
    fontFamily: "Noto Sans",
  },

  testJournalFooter: {
    marginTop: "17px",
    borderTop: "2px solid #00426b",
    display: "block",
    padding: "17px 0",
    color: "#00426b",
  },

  testJournalFooterDisclaimerFunding: {
    display: "block",
    fontSize: "12px",
    fontWeight: "500",
    marginBottom: "10px",
  },

  testJournalFooterDisclaimerP: {
    display: "block",
    fontSize: "8px",
    lineHeight: "1.2",
    marginBottom: "10px",
  },

  testJournalFooterCollaboration: {
    display: "block",
    fontSize: "12px",
    lineHeight: "1",
    marginBottom: "10px",
  },

  testJournalLogos: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
  },

  testJournalLogosNIBIB: {
    width: "190px",
    height: "38px",
  },
  testJournalLogosCE: {
    width: "128px",
    height: "38px",
  },
});

const useTestJournal = () => {
  const { t } = useTranslation();
  const {
    testingHistory,
    contactFirstName,
    contactMiddleName,
    contactLastName,
    contactStreetAddress,
    contactCity,
    contactState,
    zip,
    contactPhoneNumber,
    contactEmail,
  } = useRememberMe();

  const contactNameFields = [];
  if (contactFirstName) {
    contactNameFields.push(contactFirstName);
  }
  if (contactMiddleName) {
    contactNameFields.push(contactMiddleName);
  }
  if (contactLastName) {
    contactNameFields.push(contactLastName);
  }

  // These fields are used to determine if the contact info section should be displayed
  const contactInfoFields = [
    contactFirstName,
    contactMiddleName,
    contactLastName,
    contactStreetAddress,
    contactPhoneNumber,
    contactEmail,
  ];

  const ContactInfo = () => (
    <View style={styles.contactInfo}>
      <Text style={styles.strong}>
        {contactFirstName || contactFirstName || contactLastName
          ? contactNameFields.join(" ")
          : t("contact-h")}
      </Text>
      <View style={styles.address}>
        {contactStreetAddress ? (
          <Text style={styles.contactText}>{contactStreetAddress}</Text>
        ) : null}
        <Text style={styles.contactText}>
          {contactCity ? <>{contactCity}, </> : null}
          {contactState ? <>{contactState} </> : null}
          {zip ? <>{zip}</> : null}
        </Text>
      </View>
      {contactPhoneNumber ? (
        <Text style={styles.contactText}>
          {normalizePhoneInput(contactPhoneNumber)}
        </Text>
      ) : null}
      {contactEmail ? (
        <Text style={styles.contactText}>{contactEmail}</Text>
      ) : null}
    </View>
  );

  const TestJournalContent = () => (
    <Document>
      <Page size="A4" style={{ paddingBottom: "20px" }} wrap={true}>
        <View style={styles.testingJournalPage}>
          <View style={styles.testingJournalHeader}>
            <View style={styles.headerImageContainer}>
              <Image style={styles.testingJournalHeaderImg} src={bug} />
            </View>
            <Text style={styles.testingJournalHeaderH4}>
              makemytestcount.org
            </Text>
          </View>
          <View style={styles.testingJournalPageMain}>
            <TestingJournalPDF testingHistory={testingHistory} />
            {contactInfoFields.some((x) => x) ? <ContactInfo /> : null}
          </View>
          <View style={styles.testJournalFooter}>
            <Text style={styles.testJournalFooterDisclaimerFunding}>
              {t("footer-funding")}
            </Text>
            <View>
              <Text style={styles.testJournalFooterDisclaimerP}>
                {t("test-journal-disclaimer")}
              </Text>
              <Text style={styles.testJournalFooterCollaboration}>
                {t("footer-about")}
              </Text>
            </View>

            <View style={styles.testJournalLogos}>
              <Image style={styles.testJournalLogosNIBIB} src={nibib} />
              <Image style={styles.testJournalLogosCE} src={ce} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return {
    TestJournalContent,
  };
};

export default useTestJournal;
