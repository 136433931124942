import React from "react";
import propTypes from "prop-types";

// From https://stackoverflow.com/a/55989037
export function normalizePhoneInput(value, previousValue = "") {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
}

// Strips any character that is not a number
const stripChars = (number) => number.replace(/\D/g, "");

function NormalizedPhoneInput({ value, onChange, onBlur, inputProps = {} }) {
  const displayValue = normalizePhoneInput(value);

  const handleOnBlur = (event) => {
    const newPhone = normalizePhoneInput(event.target.value);
    onBlur(stripChars(newPhone));
  };

  const handleOnChange = (event) => {
    const newPhone = normalizePhoneInput(event.target.value);
    onChange(stripChars(newPhone));
  };

  return (
    <input
      type="tel"
      inputMode="numeric"
      autoComplete="tel"
      value={displayValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...inputProps}
    />
  );
}
NormalizedPhoneInput.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  inputProps: propTypes.object,
};

export default NormalizedPhoneInput;
