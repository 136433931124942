import React from "react";
import PropTypes from "prop-types";
import { ERROR_400, ERROR_401, ERROR_500 } from "../../../api/client";
import { useTranslation } from "react-i18next";

export const ERROR_USER_INPUT = "UserInputError";

const propTypes = {
  errorCode: PropTypes.string,
};

export const ValidationBanner = ({ errorCode }) => {
  const { t } = useTranslation();
  const VALIDATION_ERROR_400 = t("val-err-400");
  const VALIDATION_ERROR_401 = t("val-err-401-500");
  const VALIDATION_ERROR_500 = t("val-err-401-500");
  const VALIDATION_ERROR_USER_INPUT = t("val-err-user-input");

  let validationMessage = "";
  if (!errorCode) {
    validationMessage = null;
  } else if (errorCode === ERROR_400) {
    validationMessage = VALIDATION_ERROR_400;
  } else if (errorCode === ERROR_401) {
    validationMessage = VALIDATION_ERROR_401;
  } else if (errorCode === ERROR_500) {
    validationMessage = VALIDATION_ERROR_500;
  } else if (errorCode === ERROR_USER_INPUT) {
    validationMessage = VALIDATION_ERROR_USER_INPUT;
  } else {
    validationMessage = VALIDATION_ERROR_500;
  }

  return (
    <div
      className={`validation-summary ${validationMessage ? "" : "hide"}`}
      dangerouslySetInnerHTML={{ __html: validationMessage }}
    ></div>
  );
};

ValidationBanner.propTypes = propTypes;
