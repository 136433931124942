import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

/**
 * This is the PDF version of the TestingJournal component.
 */

const styles = StyleSheet.create({
  testingJournal: {
    marginBottom: "20px",
    color: "#00426b",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  testingJournalH2: {
    fontFamily: "Bitter",
    fontSize: "22px",
    fontWeight: "500",
  },

  testingJournalH2Rule: {
    width: "30px",
    borderBottom: "3px solid #e11681",
    margin: "10px 0 25px",
  },

  testingJournalOl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  testingJournalLi: {
    borderBottom: "1px solid #d9d9d9",
    padding: "5px 0",
    marginBottom: "0",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    fontWeight: "600",
    gap: "15px",
  },

  testingJournalTarget: {
    width: "70px",
    fontWeight: "600",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    textAlign: "right",
  },

  testingHistoryDate: {
    width: "70px",
    fontWeight: "600",
    fontFamily: "Noto Sans",
    fontSize: "14px",
  },

  testHistoryTest: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
  },
  testHistoryTests: {
    flex: "auto",
    display: "flex",
    flexDirection: "column",
  },

  testingJournalResultPositive: {
    color: "#e11681",
    width: "70px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    textAlign: "left",
  },
  testingJournalResultNegative: {
    color: "#199d19",
    width: "70px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    textAlign: "left",
  },
});

const CovidTestResult = ({ date, testResult }) => {
  return (
    <View key={date} style={styles.testingJournalLi}>
      <View style={styles.testHistoryTests}>
        <View style={styles.testHistoryTest}>
          <Text style={styles.testingJournalTarget}>Covid:</Text>
          <View
            style={
              testResult.toLowerCase() === "positive"
                ? styles.testingJournalResultPositive
                : styles.testingJournalResultNegative
            }
          >
            <Text>{testResult}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.testingHistoryDate}>
        {new Date(date).toLocaleDateString()}
      </Text>
    </View>
  );
};

CovidTestResult.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  testResult: PropTypes.string,
};

const MultiplexTestResult = ({ date, tests }) => {
  const { t } = useTranslation();
  const getFormattedTestNames = (testTarget) => {
    if (testTarget === "fluA") {
      return `${t("flu")} A`;
    } else if (testTarget === "fluB") {
      return `${t("flu")} B`;
    }
    return t("covid");
  };

  return (
    <View key={date} style={styles.testingJournalLi}>
      <View style={styles.testHistoryTests}>
        {tests.map(({ testTarget, testResult }) => (
          <View style={styles.testHistoryTest} key={testTarget}>
            <Text style={styles.testingJournalTarget}>
              {getFormattedTestNames(testTarget)}:
            </Text>
            <Text
              style={
                testResult.toLowerCase() === "positive"
                  ? styles.testingJournalResultPositive
                  : styles.testingJournalResultNegative
              }
            >
              {t(testResult.toLowerCase())}
            </Text>
          </View>
        ))}
      </View>
      <Text style={styles.testingHistoryDate}>
        {new Date(date).toLocaleDateString()}
      </Text>
    </View>
  );
};
MultiplexTestResult.propTypes = {
  date: PropTypes.string,
  tests: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

const TestingJournal = ({ testingHistory }) => {
  const { t } = useTranslation();

  if (!testingHistory.length) {
    return null;
  }

  return (
    <View style={styles.testingJournal}>
      <Text style={styles.testingJournalH2}>{t("testing-journal-h")}</Text>
      <View style={styles.testingJournalH2Rule} />
      <View style={styles.testingJournalOl}>
        {testingHistory.map(({ date, testResult, tests }, index) =>
          testResult ? (
            <CovidTestResult
              date={date}
              testResult={testResult}
              key={`${date}_${index}`}
            />
          ) : (
            <MultiplexTestResult
              date={date}
              tests={tests}
              key={`${date}_${index}`}
            />
          )
        )}
      </View>
    </View>
  );
};

TestingJournal.propTypes = {
  testingHistory: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default TestingJournal;
