import { useState } from "react";

/**
 * A stand-in replacement for the `useState` hook that instead uses the Browser's session storage to record the saved state.
 *
 * @param key The name of the session storage item this data is stored under
 * @param initialValue The data's value if nothing has been saved
 * @returns [value, valueSetterFunc]
 */
export default function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from session storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that stores the data in sessionstorage
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state in the session
      setStoredValue(valueToStore);
      // Save to session storage as a JSON string, so we can record complex data or empty strings
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
      // Re-raise the error if we failed to store the data
      throw error;
    }
  };

  return [storedValue, setValue];
}
