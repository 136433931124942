import React, { useEffect, useState } from "react";
import "./PartnerRequest.css";
import Form from "./Form.js";
import useShowRecaptcha from "../../partials/showRecaptcha.js";

const SUBMIT_REQUEST_URL = `${process.env.REACT_APP_API_BASE_URL}/partnerrequest`;

function App() {
  useShowRecaptcha(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async ({
    brand,
    origin,
    logoImage,
    logoImageUrl,
    themeName,
    themeHomeUrl,
    themePosUrl,
    themeNegUrl,
    themeGeneralUrl,
    contactEmail,
    comment,
  }) => {
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      let recaptchaToken = await generateCaptchaToken("submit");
      await fetch(SUBMIT_REQUEST_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "X-Captcha-Token": recaptchaToken,
        },
        body: JSON.stringify({
          brand,
          origin,
          logoImage,
          logoImageUrl,
          themeName,
          themeHomeUrl,
          themePosUrl,
          themeNegUrl,
          themeGeneralUrl,
          contactEmail,
          comment,
        }),
      });
    } catch (e) {
      console.error("Error sumbitting request", e);
      setSubmitError("An unknown error occurred.");
      setIsSubmitting(false);
      return;
    }
    setSubmitSuccess(true);
    setIsSubmitting(false);
  };

  useEffect(() => {
    const reCaptchaScriptBlock = document.createElement("script");
    reCaptchaScriptBlock.id = "recaptcha-script";
    reCaptchaScriptBlock.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    reCaptchaScriptBlock.async = true;
    document.head.appendChild(reCaptchaScriptBlock);
  }, []);

  return (
    <div className="partner-request">
      {submitSuccess ? (
        <section className="white-label-results">
          <h2>Thank You!</h2>
          <p>
            Once your request has been approved by CareEvolution, will be
            contacted by a CareEvolution team member with additional information
          </p>
        </section>
      ) : (
        <header>
          <div className="big-label">MakeMyTestCount Partner Request Form</div>
          <p>
            Please fill in this form, and someone from CareEvolution will reach
            out when your white labeled instance is ready, or to clarify
            additional details.
          </p>
        </header>
      )}
      {submitError ? (
        <div className="submit-error">
          {submitError} Please try again or contact us at{" "}
          <a href="mailto:info@makemytestcount.org">info@makemytestcount.org</a>
        </div>
      ) : null}
      {!submitSuccess ? (
        <Form onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      ) : null}
    </div>
  );
}

export default App;

function generateCaptchaToken(action) {
  return new Promise((resolve, reject) => {
    window.grecaptcha.enterprise.ready(async () => {
      let token = await window.grecaptcha.enterprise.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        { action }
      );
      resolve(token);
    });
  });
}
