import React from "react";

import { useWindowListener } from "../../helpers/useWindowListener";

import useShowRecaptcha from "../showRecaptcha";
import { useThemeContext } from "../../theme";
import { Steps, useStoreContext } from "../../Store";

import { Step01 } from "./steps/step01";
import { Step02 } from "./steps/step02";
import { Step03 } from "./steps/step03";
import { Step03FollowUp } from "./steps/step03FollowUp";
import { Step04 } from "./steps/step04";
import { Step05 } from "./steps/step05";
import { Step06 } from "./steps/step06";
import { Final } from "./steps/stepFinal";

const WizardRoutes = {
  [Steps.Step01]: <Step01 />,
  [Steps.Step02]: <Step02 />,
  [Steps.Step03]: <Step03 />,
  [Steps.Step03FollowUp]: <Step03FollowUp />,
  [Steps.Step04]: <Step04 />,
  [Steps.Step05]: <Step05 />,
  [Steps.Step06]: <Step06 />,
  [Steps.Final]: <Final />,
};

const Wizard = () => {
  const { isEmbedded, isWhiteLabeled } = useThemeContext();
  const { activeStep, setActiveStep } = useStoreContext();
  useShowRecaptcha(["Step05", "Step06"].includes(activeStep));

  // Listen to location changes (from the back button) and update the active step
  useWindowListener("hashchange", ({ newURL }) => {
    const nextStep = newURL.split("#")[1];
    setActiveStep(nextStep, null, false);
  });

  return (
    <div
      className={`step-wizard wizard wizard-step-${activeStep} ${
        isEmbedded ? "embedded" : ""
      } ${isWhiteLabeled ? "white-labeled" : ""}`}
      aria-live="assertive"
    >
      {WizardRoutes[activeStep]}
    </div>
  );
};

export default Wizard;
