import React, { useEffect } from "react";
import Footer from "../../partials/footer";
import PartnerRequest from "./PartnerRequest";
import "./plugin.css";
import pluginImage from "../../images/mmtc-plugin.png";
import whiteLabelImage from "../../images/mmtc-white-labeled.png";
import whitelabel from "../../images/whitelabel.png";
import embed from "../../images/embed.png";
import connect from "../../images/connect.png";
import Nav from "../../partials/nav";

const Plugin = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "/mmtc-plugin.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  return (
    <>
      <Nav />
      <main className="plugin-test container">
        <h1 id="make-my-test-count-plugin">Partner with MakeMyTestCount</h1>
        <div className="partner-value-props">
          <div className="partner-value-prop">
            <div className="partner-value-prop-circle">
              <img src={whitelabel} alt="Tag Icon" />
            </div>
            <div className="partner-value-prop-text">
              <h2>White Label</h2>
              <p>
                Let us create a MakeMyTestCount site with your
                organization&apos;s branding on a custom URL.
              </p>
            </div>
          </div>
          <div className="partner-value-prop">
            <div className="partner-value-prop-circle">
              <img src={embed} alt="Puzzle Icon" />
            </div>

            <div className="partner-value-prop-text">
              <h2>Embed a Plugin</h2>
              <p>Embed MakeMyTestCount on your site as a modal pop-up.</p>
            </div>
          </div>
          <div className="partner-value-prop">
            <div className="partner-value-prop-circle">
              <img src={connect} alt="Info Icon" />
            </div>

            <div className="partner-value-prop-text">
              <h2>Get in Touch</h2>
              <p>
                Contact us to learn more about partnering with MakeMyTestCount.
              </p>
              <p>
                <a
                  href="mailto:partners@makemytestcount.org"
                  target="_blank"
                  rel="noreferrer"
                >
                  Partners@MakeMyTestCount.org
                </a>
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="plugin-choices">
          <div className="intro col-2-md">
            <div className="highlighted option-module results-module">
              <div className="option-header">
                <h1>White-labeled Site </h1>
                <p>
                  Let CareEvolution create a MakeMyTestCount site with your own
                  branding.
                </p>
                <p>
                  <a
                    href="/whitelabel-demo"
                    className="button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Launch White-labeled Site Demo
                  </a>
                </p>
                <img src={whiteLabelImage} alt="example white-labeled site" />
              </div>
              <p>
                <strong>We recommend this option if:</strong>
              </p>
              <ul>
                <li>
                  You do not have an internal development team to install the
                  plugin on your existing site but do have a configurable
                  website where you can embed a new link.{" "}
                </li>
                <li>
                  You do not mind if users are directed to a different URL as
                  long as the website is branded with your organization name,
                  logo, and some additional details (see next section).
                </li>
              </ul>
            </div>
            <div className="highlighted option-module results-module">
              <div className="option-header">
                <h1>JavaScript Plugin</h1>
                <p>Embed MakeMyTestCount on your own site as a modal popup. </p>
                <p>
                  <button
                    className="button"
                    data-open-mmtc-dialog
                    data-mmtc-brand="whitelabel-demo"
                  >
                    Launch Plugin Demo
                  </button>
                </p>
                <img src={pluginImage} alt="example white-labeled site" />
              </div>
              <p>
                <strong>We recommend this option if:</strong>
              </p>
              <ul>
                <li>
                  You have technical resources to install and incorporate the
                  plugin on your existing site.
                </li>
                <li>
                  You want the application to feel like it is part of your
                  existing site.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <PartnerRequest />
      </main>
      <Footer />
    </>
  );
};

export default Plugin;
