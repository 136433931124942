import React from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TestTypeButton from "../TestTypeButton.jsx";
import testKits from "../../testKits.js";
import { getAvailableTestTypes } from "../utils.js";
import { useThemeContext } from "../../../theme";
import { useStoreContext, Steps } from "../../../Store";

export const Step03 = () => {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const { tests, setTestType, testType, setActiveStep } = useStoreContext();
  const { themeTestTypes = [] } = useThemeContext();
  const handleSetTestTypes = (newTestType) => {
    setTestType(newTestType);
    window.dataLayer.push({
      event: "interactionEvent",
      event_name: "test_select",
      test_name: newTestType,
    });
    setActiveStep(Steps.Step04, { tests, newTestType });
  };

  const handleOther = () => {
    setActiveStep(Steps.Step03FollowUp);
  };

  const handleOtherOnKeyDown = (event) => {
    if (["Up", "ArrowUp", "Left", "ArrowLeft"].includes(event.key)) {
      document.querySelector("#roche").focus();
    }
  };

  const handleBackButton = () => {
    setActiveStep(Steps.Step02);
  };

  const testsAvailable = getAvailableTestTypes(tests, themeTestTypes);

  return (
    <section className="wizard-step-wrapper testType01">
      <button
        className="back-button"
        onClick={handleBackButton}
        type="button"
        aria-label="Back to Step 1"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <fieldset className="container">
        <legend className="big-label" id="test-type-q">
          {t("test-type-q")}
        </legend>
        <div className="testbox-buttons" role="radiogroup">
          {testsAvailable.map((testKit) => (
            <TestTypeButton
              key={testKit}
              onClick={handleSetTestTypes}
              checked={testType === testKit}
              id={testKit}
              name="test-type"
              value={testKit}
              text={testKits[testKit]?.name}
              image={testKits[testKit]?.imageSrc}
              alt={
                resolvedLanguage === "es"
                  ? testKits[testKit]?.altEs
                  : testKits[testKit]?.alt
              }
            />
          ))}
          {tests.length === 1 && !themeTestTypes.length && (
            <div className="test-type-button input-button">
              <button
                className="button tertiary show-more-button"
                onClick={handleOther}
                onKeyDown={handleOtherOnKeyDown}
                type="button"
              >
                {t("other")}
              </button>
            </div>
          )}
        </div>
      </fieldset>
    </section>
  );
};
