import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import {
  languageCodeMapping as languages,
  languageOrdering,
} from "./languages.js";

let resources = {};
const whitelist = [];

whitelist.push(...Object.values(languages));
whitelist.forEach(
  (languageCode) =>
    (resources[languageCode] = {
      translations: require(`./locales/${languageCode}.json`),
    })
);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    fallbackLng: "en",
    resources,
    whitelist,
    detection: {
      order: [
        "path",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
      ],
      caches: [],
      lookupFromPathIndex: 1,
      checkWhitelist: true,
    },
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
  });

i18n.resolvedLanguages = whitelist;

export { languageOrdering, languages };
export default i18n;
