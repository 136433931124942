import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import FocusTrap from "focus-trap-react";
import { usePopper } from "react-popper";
import { useTranslation } from "react-i18next";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import "./DatePicker.css";

const DatePickerProps = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  dayPickerProps: PropTypes.object,
  required: PropTypes.bool,
  dialogPosition: PropTypes.oneOf(["top", "bottom"]),
};


const DatePicker = ({
  label,
  date,
  id,
  onChange,
  minDate = undefined,
  maxDate = undefined,
  dayPickerProps = {},
  dialogPosition="bottom",
}) => {
  const { t } = useTranslation();
  const [shouldEditDate, setShoudlEditDate] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });

  const handleEditDate = () => {
    setShoudlEditDate(!shouldEditDate);
  };

  const handleChangeDate = (newDate) => {
    if (newDate !== date) {
      onChange(newDate);
    }
    setShoudlEditDate(false);
  };
  
  const startMonth = minDate ? new Date(minDate.getFullYear(), minDate.getMonth()) : undefined;
  const endMonth = maxDate ? new Date(maxDate.getFullYear(), maxDate.getMonth()) : undefined;

  return (
    <fieldset className="date-picker form-group">
      <label className="label" id={`${id}-q`}>
        {label}
      </label>
      <div className="date-button-container" ref={popperRef}>
        <div className="date">
          {date ? new Date(date).toLocaleDateString() : ""}
        </div>
        <button
          className="button"
          onClick={handleEditDate}
          type="button"
          ref={buttonRef}
          aria-labelledby={`${id}-q`}
        >
          {t("edit")}
        </button>
      </div>
      {shouldEditDate && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            fallbackFocus: buttonRef.current || undefined,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className={`date-picker-dialog-sheet ${dialogPosition}`}
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="DayPicker calendar"
          >
            <DayPicker
              initialFocus={shouldEditDate}
              mode="single"
              captionLayout="dropdown"
              id={id}
              name={id}
              selected={date ? date : undefined}
              onSelect={handleChangeDate}
              aria-labelledby={`${id}-q`}
              startMonth={startMonth}
              endMonth={endMonth}
              disabled={{ before: minDate, after: maxDate }} 
              footer={
                date
                  ? `You picked ${date.toLocaleDateString()}.`
                  : "Please pick a date."
              }
              {...dayPickerProps}
            />
          </div>
        </FocusTrap>
      )}
    </fieldset>
  );
};

DatePicker.propTypes = DatePickerProps;

export default DatePicker;
