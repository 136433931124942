import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import { useThemeContext } from "../theme";

const Layout = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const theme = useThemeContext();

  useEffect(() => {
    const urlSegments = location.pathname.split("/");
    const languages = i18n.resolvedLanguages;
    let languageIndex = -1;
    for (let i = 0; i < languages.length; i++) {
      const availableLanguage = languages[i];
      languageIndex = urlSegments.indexOf(availableLanguage);
      if (languageIndex > -1) {
        break;
      }
    }
    if (languageIndex > -1) {
      const locale = urlSegments[languageIndex];
      if (locale !== i18n.resolvedLanguage) {
        i18n.changeLanguage(locale);
      }
    }
  }, [i18n, location]);

  return (
    <div
      className={`${theme.brand || "default"}-branding language-${
        i18n.resolvedLanguage
      }`}
    >
      <Outlet />
    </div>
  );
};

export default Layout;
