import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./Tooltip.css";

const TooltipProps = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  position: PropTypes.string,
  icon: PropTypes.object,
};

const Tooltip = ({ id, text, position="top", icon=faInfoCircle }) => {
  return (
    <div
      className={`tooltip align-${position}`}
      aria-label={text}
      role="tooltip"
    >
      <FontAwesomeIcon icon={icon} />
      <span className="tooltiptext" id={id}>
        {text}
      </span>
    </div>
  );
};

Tooltip.propTypes = TooltipProps;

export default Tooltip;
