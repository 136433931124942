import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PanelPropTypes = {
  label: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  activeTab: PropTypes.number,
  index: PropTypes.number,
  activateTab: PropTypes.func,
};

function Panel(props) {
  const { label, content, image, activeTab, index, activateTab } = props;
  const { t } = useTranslation();

  const [height, setHeight] = useState(0);
  const panelWrapperRef = React.createRef();
  const isActive = activeTab === index;

  useEffect(() => {
    window.setTimeout(() => {
      const el = panelWrapperRef.current;
      if (!el || activeTab !== index) {
        setHeight(0);
        return;
      }
      const scrollHeight = el.querySelector(".panel__content").scrollHeight;
      setHeight(scrollHeight);
    }, 333);
  }, [panelWrapperRef, activeTab, index]);

  const handleOnClick = (event) => {
    event.preventDefault();
    activateTab(props.index);
  };

  const innerStyle = {
    height: `${isActive ? height : 0}px`,
  };
  return (
    <div
      className={`panel ${isActive ? "active" : "inactive"}`}
      ref={panelWrapperRef}
    >
      <button
        className="panel__label"
        role="tab"
        onClick={handleOnClick}
        aria-expanded={isActive}
        aria-roledescription={t("faq")}
      >
        {label}
      </button>
      {isActive ? (
        <div className="panel__inner" style={innerStyle} aria-live="assertive">
          <div
            className="panel__content text-gray-700"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          {image ? (
            <img className="panel__image" src={image} alt={label} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

Panel.propTypes = PanelPropTypes;

const accordionPropTypes = {
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.string,
    })
  ),
};

export const Accordion = (props) => {
  const [activeTab, setActiveTab] = useState(null);
  const { panels } = props;

  const handleTabSelection = (index) => {
    setActiveTab(activeTab === index ? -1 : index);
  };

  return (
    <div className="accordion" role="tablist">
      {panels.map((panel, index) => (
        <Panel
          key={index}
          activeTab={activeTab}
          index={index}
          activateTab={handleTabSelection}
          {...panel}
        />
      ))}
    </div>
  );
};

Accordion.propTypes = accordionPropTypes;
