import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

// Object of keys and their default values
const properties = {
  shouldRememberMe: false,
  differentZip: false,
  shouldShareInfo: null,
  should: false,
  age: "",
  sex: "",
  race: [],
  ethnicity: "",
  contactFirstName: "",
  contactMiddleName: "",
  contactLastName: "",
  contactStreetAddress: "",
  contactCity: "",
  contactState: "",
  zip: "",
  contactEmail: "",
  contactPhoneNumber: "",
  contactDateOfBirth: "",
  testingHistory: [],
};

const getInitialState = () => {
  const initialState = {};
  Object.keys(properties).forEach((key) => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return defaultValue
      initialState[key] = item ? JSON.parse(item) : properties[key];
    } catch (error) {
      // If error also return defaultValue
      console.error(error);
      initialState[key] = properties[key];
    }
  });

  return initialState;
};

function reducer(state, action) {
  if (action.type === "setValue") {
    let newState = {
      ...state,
      [action.key]: action.value,
    };

    // If the user unchecks/turns off shouldRememberMe, clear properties
    if (action.key === "shouldRememberMe" && !action.value) {
      Object.keys(properties).forEach((key) => {
        try {
          window.localStorage.removeItem(key);
        } catch (e) {
          console.error(e);
        }
      });

      // also reset the state
      newState = getInitialState();
    }
    // If we are supposed to remember content, update the state in localStorage
    if (newState.shouldRememberMe) {
      // Save to local storage as a JSON string, so we can record complex data or empty strings
      Object.keys(properties).forEach((key) => {
        try {
          window.localStorage.setItem(key, JSON.stringify(newState[key]));
        } catch (e) {
          console.error(e);
        }
      });
    }
    return newState;
  }
  throw Error("Unknown action.");
}

const RememberMeContext = createContext(getInitialState());

export function RememberMeContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const setShouldRememberMe = (value) =>
    dispatch({ type: "setValue", key: "shouldRememberMe", value });
  const setDifferentZip = (value) =>
    dispatch({ type: "setValue", key: "differentZip", value });
  const setShouldShareInfo = (value) =>
    dispatch({ type: "setValue", key: "shouldShareInfo", value });
  const setAge = (value) => dispatch({ type: "setValue", key: "age", value });
  const setSex = (value) => dispatch({ type: "setValue", key: "sex", value });
  const setRace = (value) => dispatch({ type: "setValue", key: "race", value });
  const setEthnicity = (value) =>
    dispatch({ type: "setValue", key: "ethnicity", value });
  const setContactFirstName = (value) =>
    dispatch({ type: "setValue", key: "contactFirstName", value });
  const setContactMiddleName = (value) =>
    dispatch({ type: "setValue", key: "contactMiddleName", value });
  const setContactLastName = (value) =>
    dispatch({ type: "setValue", key: "contactLastName", value });
  const setContactStreetAddress = (value) =>
    dispatch({ type: "setValue", key: "contactStreetAddress", value });
  const setContactCity = (value) =>
    dispatch({ type: "setValue", key: "contactCity", value });
  const setContactState = (value) =>
    dispatch({ type: "setValue", key: "contactState", value });
  const setZip = (value) => dispatch({ type: "setValue", key: "zip", value });
  const setContactEmail = (value) =>
    dispatch({ type: "setValue", key: "contactEmail", value });
  const setContactPhoneNumber = (value) =>
    dispatch({ type: "setValue", key: "contactPhoneNumber", value });
  const setContactDateOfBirth = (value) =>
    dispatch({ type: "setValue", key: "contactDateOfBirth", value });
  const setTestingHistory = (value) =>
    dispatch({ type: "setValue", key: "testingHistory", value });

  const rememberMeContext = {
    ...state,
    setShouldRememberMe,
    setDifferentZip,
    setShouldShareInfo,
    setAge,
    setSex,
    setRace,
    setEthnicity,
    setContactFirstName,
    setContactMiddleName,
    setContactLastName,
    setContactStreetAddress,
    setContactCity,
    setContactState,
    setZip,
    setContactEmail,
    setContactPhoneNumber,
    setContactDateOfBirth,
    setTestingHistory,
  };

  return (
    <RememberMeContext.Provider value={rememberMeContext}>
      {children}
    </RememberMeContext.Provider>
  );
}

RememberMeContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useRememberMe = () => React.useContext(RememberMeContext);
