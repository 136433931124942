import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import TagManager from "react-gtm-module";

import { RememberMeContextProvider } from "./rememberMe";
import { ThemeContextProvider } from "./theme";
import { StoreContextProvider } from "./Store";

import "./styles/fontawesome-free-6.5.1-web/css/all.css";
import "./styles/_main.css";

import Layout from "./pages/layout";
import Home from "./pages/home";
import Plugin from "./pages/plugin/index";
import PluginUpdates from "./pages/plugin/PluginUpdates";
import TestJournalPage from "./pages/TestJournalPage";

import SampleLinks from "./pages/SampleLinks";
import faviconUrl from "./images/favicons/favicon.ico";
import favicon192Url from "./images/favicons/favicon-192.png";

if (process.env.NODE_ENV === "production") {
  // Note that makemytestcount.dev is also identified as prod, so this only removes traffic from your local dev env
  const tagManagerArgs = {
    gtmId: "GTM-THX7Q6Z", // owned by CareEvolution
  };
  TagManager.initialize(tagManagerArgs);
}
if (process.env.NODE_ENV === "development") {
  // only works in local dev env
  const tagManagerArgs = {
    gtmId: "GTM-PFTHFP9N", // owned by CareEvolution
  };
  TagManager.initialize(tagManagerArgs);
}
const rtlLanguages = ["ar", "fa", "ur", "ps"];

const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ThemeContextProvider>
        <RememberMeContextProvider>
          <StoreContextProvider>
            <Layout />
          </StoreContextProvider>
        </RememberMeContextProvider>
      </ThemeContextProvider>
    ),
    children: [
      { path: "/embed-plugin", element: <Plugin /> },
      { path: "/plugin-updates", element: <PluginUpdates /> },
      {
        path: "/whitelabel-link-example/:linkType/",
        element: <SampleLinks />,
      },
      { path: "/:lang?/test-results", element: <TestJournalPage /> },
      {
        path: "/es-ES/test-results",
        loader: () => redirect("/es/test-results"),
      },
      {
        path: "/:brand?/:lang?",
        element: <Home />,
      },
      {
        path: "*",
        element: <Home />,
      },
    ],
  },
]);

export default function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const injectMeta = () => {
      const faviconElement = document.getElementById("favicon");
      const favicon192Element = document.getElementById("favicon192");
      const metaDescription = document.getElementById("description");
      const hreflangElement = document.getElementById("hreflang");
      hreflangElement.href = window.location.origin + window.location.pathname;
      hreflangElement.hreflang = i18n.resolvedLanguage;
      faviconElement.href = faviconUrl;
      favicon192Element.href = favicon192Url;
      metaDescription.content = t("site-description");
      document.documentElement.dir = rtlLanguages.includes(
        i18n.resolvedLanguage
      )
        ? "rtl"
        : "ltr";
    };
    injectMeta();
  });

  return <RouterProvider router={Router} />;
}
