import React from "react";
import { useParams } from "react-router-dom";
import bug from "../images/logo.svg";
import Footer from "../partials/footer";
import "../styles/pages/sample-link.css";

const SampleLinks = () => {
  const { linkType } = useParams();

  const onClickBack = () => {
    window.history.back();
  };

  return (
    <>
      <nav className="main-navigation" role="navigation">
        <div className="container">
          <a href="/" className="logo">
            <img src={bug} alt="Make My Test Count" />
          </a>
        </div>
      </nav>
      <main className="sample-link container">
        <div>
          <h1>Thanks for trying out the white-labeled feature!</h1>
          <p>
            This link is for the <strong>{linkType}Url</strong> parameter
          </p>
          <div className="back-button-container">
            <button onClick={onClickBack}>Go Back</button>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default SampleLinks;
