import React from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TestTypeButton from "../TestTypeButton.jsx";
import testKits, { testKitsSecondary } from "../../testKits.js";
import { useStoreContext, Steps } from "../../../Store";

export const Step03FollowUp = () => {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const { setTestType, testType, tests, setActiveStep } = useStoreContext();

  const handleSetTestTypes = (testTypeValue) => {
    setTestType(testTypeValue);
    if (testTypeValue !== "Other") {
      window.dataLayer.push({
        event: "interactionEvent",
        event_name: "test_select",
        test_name: testTypeValue,
      });
    }
    setActiveStep(Steps.Step04, { testType: testTypeValue, tests });
  };

  const handleBackButton = () => {
    setActiveStep(Steps.Step03);
  };

  return (
    <section className="wizard-step-wrapper testType01">
      <button
        className="back-button"
        onClick={handleBackButton}
        type="button"
        aria-label="Back to Step 1"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <fieldset className="container">
        <legend className="big-label" id="test-type-follow-up-q">
          {t("test-type-q")}
        </legend>
        <div className="testbox-buttons" role="radiogroup">
          {testKitsSecondary.map((testKit) => (
            <TestTypeButton
              key={testKit}
              onClick={handleSetTestTypes}
              checked={testType === testKit}
              id={testKit}
              name="test-type"
              value={testKit}
              text={testKits[testKit]?.name}
              image={testKits[testKit]?.imageSrc}
              alt={
                resolvedLanguage === "es"
                  ? testKits[testKit]?.altEs
                  : testKits[testKit]?.alt
              }
            />
          ))}
        </div>
      </fieldset>
    </section>
  );
};
