import {
  testKitsPrimary,
  testKitsSecondary,
  multiplexTestKits,
} from "../testKits";

export const getAvailableTestTypes = (tests, themeTestTypes = []) => {
  let testsAvailable;
  if (themeTestTypes.length) {
    // Include both the primary and secondary test kits if the themeTestTypes is available,
    // because it may only be in the secondary test kits
    testsAvailable =
      tests.length > 1
        ? multiplexTestKits
        : [...testKitsPrimary, ...testKitsSecondary];
    testsAvailable = themeTestTypes
      ? testsAvailable.filter((test) => themeTestTypes.includes(test))
      : testsAvailable;
  } else {
    testsAvailable = tests.length > 1 ? multiplexTestKits : testKitsPrimary;
  }
  return testsAvailable;
};
