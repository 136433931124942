import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import bug from "../images/logo.svg";
import LanguageSwitcher from "./language-switcher";
import { useThemeContext } from "../theme";
import FocusTrap from "focus-trap-react";
import "./nav.css";
import { useEffect } from "react";

const Bars = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
    ></path>
  </svg>
);

const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M6 18L18 6M6 6l12 12"
    ></path>
  </svg>
);

export default function Nav() {
  const { t, i18n } = useTranslation();
  const theme = useThemeContext();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownFocusOpen, setIsDropdownFocusOpen] = useState(null); 
  const [isDropdownHoverOpen, setIsDropdownHoverOpen] = useState(null); 

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = (index) => {
    setIsDropdownFocusOpen(isDropdownFocusOpen === index ? null : index); // Toggle dropdown by index
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click is outside the dropdown or the button
      if (
        !event.target.closest(".more-info-toggle") &&
        !event.target.closest(".more-info-items")
      ) {
        setIsDropdownFocusOpen(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownFocusOpen, setIsDropdownFocusOpen]);

  const { resolvedLanguage } = i18n;
  const navItems = [
    {
      link: `https://report.makemytestcount.org${
        resolvedLanguage === "es" ? "/es" : ""
      }/test-results`,
      text: t("test-journal-h1"),
    },
    {
      link: `https://learn.makemytestcount.org${
        resolvedLanguage === "es" ? "/es-ES" : ""
      }/covid-and-flu-in-your-area`,
      text: t("covid-flu-in-your-area-h1"),
    },
    {
      text: t("nav-more-info"),
      children: [
        {
          link: `https://learn.makemytestcount.org${
            resolvedLanguage === "es" ? "/es-ES" : ""
          }/frequently-asked-questions/home-testing`,
          text: t("nav-home-test-faq"),
        },
        {
          link: `https://learn.makemytestcount.org${
            resolvedLanguage === "es" ? "/es-ES" : ""
          }/frequently-asked-questions/1`,
          text: t("nav-site-faq"),
        },
        {
          link: `https://learn.makemytestcount.org${
            resolvedLanguage === "es" ? "/es-ES" : ""
          }/about-us`,
          text: t("nav-about"),
        },
        {
          link: `https://learn.makemytestcount.org${
            resolvedLanguage === "es" ? "/es-ES" : ""
          }/for-partners`,
          text: t("nav-add-us"),
        },
      ],
    },
  ];

  const shouldShowNavBox =
    theme.isWhiteLabeled && (theme.shouldShowName || theme.themeLogoUrl);
  
  const renderNavItem = (item, index) => {
    if (item.children) {
      return (
        <li key={index} className="more-info" onMouseLeave={() => setIsDropdownHoverOpen(null)}>
          <button
            className={`more-info-toggle ${isDropdownFocusOpen === index || isDropdownHoverOpen === index ? 'open' : ''}`}
            onClick={() => toggleDropdown(index)}
            aria-expanded={isDropdownFocusOpen === index || isDropdownHoverOpen === index}
            onMouseEnter={() => setIsDropdownHoverOpen(index)}
          >
            {item.text}
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="more-info-caret" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z"></path></svg>
          </button>
          <ul
            className={`more-info-items ${
              isDropdownFocusOpen === index || isDropdownHoverOpen === index ? "visible" : ""
            }`}
          >
            {item.children.map((child, childIndex) => (
              <li key={childIndex}>
                <a href={child.link}>{child.text}</a>
              </li>
            ))}
          </ul>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <a href={item.link}>{item.text}</a>
        </li>
      );
    }
  };

  const standardNav = (
    <nav className="nav-links desktop" role="navigation">
      <ul>
        {navItems.map((item, index) => renderNavItem(item, index))}
      </ul>
    </nav>
  );

  const mobileNav = (
    <nav
      className={`nav-links mobile ${isMobileMenuOpen ? "is-visible" : ""}`}
      role="navigation"
      aria-hidden={!isMobileMenuOpen}
    >
      <ul className={shouldShowNavBox ? "whitelabeled-nav-box" : ""}>
        {theme.isWhiteLabeled &&
        (theme.shouldShowName || theme.themeLogoUrl) ? (
          <li className="whitelabeled-nav-header">
            <span>{t("powered-by")}</span>
            <a className="nav-logo" href="https://learn.makemytestcount.org">
              <img src={bug} alt="Make My Test Count" />
            </a>
          </li>
        ) : null}
        {navItems.map((item, index) => renderNavItem(item, index))}
      </ul>
    </nav>
  );

  const navContent = (
    <header
      className={`nav-wrapper ${theme.isWhiteLabeled ? "whitelabeled" : ""}`}
      role="banner"
    >
      <div className="nav-container">
        <div className="flex-container">
          <div className="nav-left-content">
            <a
              className="nav-logo"
              href={
                theme.themeHomeUrl
                  ? theme.themeHomeUrl
                  : "https://learn.makemytestcount.org"
              }
            >
              <img
                src={theme.themeLogoUrl || bug}
                alt={theme.themeName ? theme.themeName : "Make My Test Count"}
              />
            </a>
            {theme.isWhiteLabeled && theme.shouldShowName ? (
              <a
                className="whitelabel-name"
                href={
                  theme.themeHomeUrl
                    ? theme.themeHomeUrl
                    : "https://learn.makemytestcount.org"
                }
              >
                {theme.themeName}
              </a>
            ) : null}
            {!theme.isWhiteLabeled && !theme.shouldShowName ? (
              standardNav
            ) : null}
          </div>
          <div
            className={`mobile-nav ${
              theme.isWhiteLabeled || theme.shouldShowName ? "show-always" : ""
            }`}
          >
            <button
              className="mobile-nav-toggle link-button"
              onClick={toggleMobileMenu}
              title={isMobileMenuOpen ? "Close Menu" : "Open Menu"}
            >
              {isMobileMenuOpen ? <Close /> : <Bars />}
            </button>
            {mobileNav}
          </div>
          <LanguageSwitcher />
        </div>
      </div>
    </header>
  );

  if (isMobileMenuOpen) {
    return <FocusTrap>{navContent}</FocusTrap>;
  }
  return navContent;
}