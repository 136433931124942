import React from "react";
import { t } from "i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import useTestJournal from "../partials/TestJournal";
import TestingJournal from "../partials/wizard/steps/TestingJournal";
import Nav from "../partials/nav";
import { useRememberMe } from "../rememberMe";
import "./TestJournalPage.css";

const TestJournalPage = () => {
  const { TestJournalContent } = useTestJournal();
  const { testingHistory } = useRememberMe();

  if (testingHistory.length === 0) {
    return (
      <>
        <Nav />
        <main className="test-journal-page">
          <div className="container">
            <div className="right-content">
              <h1>{t("test-journal-h1")}</h1>
              <p>{t("test-journal-no-tests-p")}</p>
              <a href="/" className="button test-journal-button">
                {t("nav-report")}
              </a>
              <br />
              <ul>
                <li>{t("test-journal-no-tests-li1")}</li>
                <li>{t("test-journal-no-tests-li2")}</li>
                <li>{t("test-journal-no-tests-li3")}</li>
                <li className="no-bullet"><a href="https://learn.makemytestcount.org/frequently-asked-questions/1">{t("learn-more")}</a></li>
              </ul>
            </div>
          </div>
        </main>
      </>
    );
  }
  return (
    <>
      <Nav />
      <main className="test-journal-page">
        <div className="container">
          <div className="left-content">
            <h1>{t("test-journal-h1")}</h1>
            <PDFDownloadLink
              className="button test-journal-button"
              fileName="test-results.pdf"
              document={<TestJournalContent />}
            >
              {t("download-journal")}
            </PDFDownloadLink>
            <br />
            <a href="/" className="button test-journal-button">
                {t("report-another")}
              </a>
            <br />
            <TestingJournal testingHistory={testingHistory} />
          </div>
          <div className="right-content">
            <p><strong>{t("test-journal-p1")}</strong></p>
            <ul>
              <li>{t("test-journal-li1")}</li>
              <li>{t("test-journal-li2")}</li>
            </ul>
            <br />
            <p><strong>{t("test-journal-p2")}</strong></p>
            <ul>
              <li>{t("test-journal-li3")}</li>
              <li>{t("test-journal-li4")}</li>
            </ul>
          </div>
        </div>
      </main>
    </>
  );
};

export default TestJournalPage;
