import React, { useEffect } from "react";
import { t } from "i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { useThemeContext } from "../../../theme";
import { Accordion } from "../../accordion";
import TestingJournal from "./TestingJournal";
import { useStoreContext } from "../../../Store";
import { useRememberMe } from "../../../rememberMe";
import useTestJournal from "../../TestJournal";

import isMobile from 'ismobilejs';

export const Final = () => {
  const { tests, diagnosticType } = useStoreContext();
  const theme = useThemeContext();
  const { testingHistory } = useRememberMe();
  const { TestJournalContent } = useTestJournal();
  const userAgent = window.navigator.userAgent;

  const shareClick = () => {
    try {
      if (isMobile(userAgent).phone) {
        // Open SMS app with pre-filled message
        const phoneNumber = '';
        const message = t("sms-message");
        const smsURI = `sms:${phoneNumber}?&body=${encodeURIComponent(message)}`;
        window.location.href = smsURI;
        return
      }
    } catch (error) {
      console.error("Error opening SMS app", error);
    }
    try {
      // Open email client with pre-filled message
      const email = '';
      const subject = t("email-subject");
      const body = t("email-body");
      const mailtoURI = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoURI;
    } catch (error) {
      console.error("Error opening email client", error);
    }
  };

  const covidPanelsPos = [
    {
      label: t("covid-positive-li1-h"),
      //image: notify,
      content: t("covid-positive-li1"),
    },
    {
      label: t("covid-positive-li2-h"),
      //image: isolate,
      content:
        t("covid-positive-li2"),
    },
    {
      label: t("covid-positive-li3-h"),
      //image: mask,
      content:
        t("covid-positive-li3"),
    },
    /* Disabled
    {
      label: t("covid-positive-li4-h"),
      image: symptoms,
      content:
        t("covid-positive-li4") +
        ' <a target="_blank" rel="noreferrer" href="https://whentotest.org/frequently-asked-questions/ive-tested-positive#how-do-i-know-if-i-need-to-go-to-the-hospital">' +
        t("learn-more") +
        "</a>",
    },
    */
  ];
  const covidPanelsNeg = [
    {
      label: t("covid-negative-p3"),
      content:
        "<ul><li>" +
        (diagnosticType === "antigen"
          ? t("covid-negative-li1") + "</li><li>"
          : "") +
        t("covid-negative-li2") +
        "</li><li>" +
        (diagnosticType === "molecular"
          ? t("covid-molecular-negative-li3")
          : t("covid-antigen-negative-li3")) +
        "</li></ul>",
    },
    {
      label: t("covid-negative-p4"),
      content:
        "<ul><li>" +
        (diagnosticType === "molecular"
          ? t("covid-molecular-negative-li4")
          : t("covid-antigen-negative-li4")) +

        "</a></li>" +
        /*
        (diagnosticType === "antigen"
          ? `<li>${t("covid-antigen-negative-li5")}</li>`
          : "") +
        */
        "</ul>",
    },
  ];

  const clearForm = () => {
    window.dataLayer.push({
      event: "interactionEvent",
      event_name: "report_another_test",
    });

    // TODO: clear form
    window.location.reload(false);
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 200);
  });

  const hasPositiveCovidResults = tests.some(
    ({ testResult, testTarget }) =>
      testTarget === "covid" && testResult === "Positive"
  );

  const hasFluResults = tests.some(
    ({ testTarget }) => testTarget === "fluA" || testTarget === "fluB"
  );
  const hasPositiveFluResults = tests.some(
    ({ testResult, testTarget }) =>
      ["fluA", "fluB"].includes(testTarget) && testResult === "Positive"
  );

  const hasPositiveModule =
    theme.themePositiveUrl &&
    (hasPositiveCovidResults || hasPositiveFluResults) &&
    theme.shouldShowResources;
  const hasNegativeModule =
    theme.themeNegativeUrl &&
    !(hasPositiveCovidResults || hasPositiveFluResults) &&
    theme.shouldShowResources;
  const hasPartnerModule = hasNegativeModule || hasPositiveModule;

  return (
    <section className="wizard-step-wrapper thankYou">
      <div className="confirmation-banner">
        <h2>{t("results-confirm-h")}</h2>
        <p><strong>{t("results-invite-p1")}</strong> {t("results-invite-p2")}</p>
        <button
          className={hasPartnerModule ? "quiet-button" : "button"}
          onClick={shareClick}
          type="button"
        >
          {t("report-share")}
        </button>
      </div>
      <div className="container form-wrapper">
        <div className="col-2-md">
          <div className="next-steps-module">
            <h1 className="with-rule">{t("next-steps")}</h1>
            {hasFluResults ? <h2>{t("covid")}</h2> : null}
            {hasPositiveCovidResults ? (
              <>
                <p
                  dangerouslySetInnerHTML={{ __html: t("covid-positive-p1") }}
                ></p>
                <Accordion panels={covidPanelsPos} />
              </>
            ) : (
              <>
                <p>
                  <strong>{t("covid-negative-p2-1")}</strong>{" "}
                  {t("covid-negative-p2-2")}
                </p>
                <Accordion panels={covidPanelsNeg} />
              </>
            )}
            {hasFluResults ? (
              <>
                <hr />
                <h2>{t("flu")}</h2>
                {hasPositiveFluResults ? (
                  <>
                    <p
                      dangerouslySetInnerHTML={{ __html: t("flu-positive-h") }}
                    />
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("flu-positive-li1"),
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("flu-positive-li2"),
                        }}
                      />
                    </ul>
                  </>
                ) : (
                  <>
                    <p
                      dangerouslySetInnerHTML={{ __html: t("flu-negative-h") }}
                    />
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("flu-negative-li1"),
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("flu-negative-li2"),
                        }}
                      />
                    </ul>
                  </>
                )}
              </>
            ) : null}
            {hasPositiveModule ? (
              <div className="partner-module results-module highlighted">
                <h2 className="with-rule">{t("resources")}</h2>
                <p>
                  {theme.themeName} {t("has-info")}{" "}
                  <span className="to-lower-case">{t("positive")}</span>:
                </p>

                {/* positive results link */}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={theme.themePositiveUrl}
                  className="button"
                >
                  {t("partner-button")}{" "}
                  <span className="to-lower-case">{t("positive")}</span>
                </a>

                {/*  generic contact link */}
                {theme.themeGeneralUrl ? (
                  <div className="more-info">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={theme.themeGeneralUrl}
                    >
                      {t("more-info")}
                    </a>
                  </div>
                ) : null}
              </div>
            ) : null}
            {hasNegativeModule ? (
              <div className="partner-module results-module highlighted">
                <h2 className="with-rule">{t("resources")}</h2>
                <p>
                  {theme.themeName} {t("has-info")}{" "}
                  <span className="to-lower-case">{t("negative")}</span>:
                </p>

                {/* negative results link */}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={theme.themeNegativeUrl}
                  className="button"
                >
                  {t("partner-button")}{" "}
                  <span className="to-lower-case">{t("negative")}</span>
                </a>

                {/*  generic contact link */}
                {theme.themeGeneralUrl ? (
                  <div className="more-info">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={theme.themeGeneralUrl}
                    >
                      {t("more-info")}
                    </a>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="results-right-column">
            <h1 className="with-rule">{t("test-journal-h1")}</h1>
            <div className="post-report-journal-buttons">
              <PDFDownloadLink
                className="button test-journal-button"
                fileName="testing-results.pdf"
                document={<TestJournalContent />}
              >
                {t("download-journal")}
              </PDFDownloadLink>
              <button
                className="button test-journal-button"
                onClick={clearForm}
                type="button"
              >
                {t("report-another")}
              </button>
            </div>
            <TestingJournal testingHistory={testingHistory} />
          </div>
        </div>
      </div>
    </section>
  );
};
