const PARTICIPANT_IDENTIFIER_KEY = "participantIdentifier";
const PARTICIPANT_SECRET_KEY = "participantSecret";

export const ERROR_400 = "400";
export const ERROR_401 = "401";
export const ERROR_401_RETRY = "401-Retry";
export const ERROR_500 = "500";
const RETRIES = 5;

export function submitResults(results) {
  return fetchWithRetry("submit", async (recaptchaToken, retryNumber) => {
    return await submitResultsInternal(results, recaptchaToken, retryNumber);
  });
}

export function updateResults(results, resultKey) {
  return fetchWithRetry("update", async (recaptchaToken, retryNumber) => {
    return await updateResultsInternal(results, resultKey, recaptchaToken, retryNumber);
  });
}

async function fetchWithRetry(captchaAction, fetchFunction) {
  let retriesRemaining = RETRIES;

  do {
    try {
      const recaptchaToken = await generateCaptchaToken(captchaAction);
      return await fetchFunction(recaptchaToken, RETRIES - retriesRemaining);
    } catch (error) {
      if (error.message === ERROR_401_RETRY) {
        retriesRemaining--;
        const delay = 500 * 2 * (RETRIES - retriesRemaining);
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        throw error;
      }
    }
  } while (retriesRemaining >= 0);
}

async function submitResultsInternal(results, captchaToken, retryNumber) {
  let participantIdentifier = window.localStorage.getItem(
    PARTICIPANT_IDENTIFIER_KEY
  );
  let participantSecret = window.localStorage.getItem(PARTICIPANT_SECRET_KEY);
  if (participantIdentifier && participantSecret) {
    results.participantIdentifier = participantIdentifier;
    results.participantSecret = participantSecret;
  }
  let url = `${process.env.REACT_APP_API_BASE_URL}/results`;
  var urlSearchParams = new URLSearchParams();
  if (retryNumber) {
    urlSearchParams.append("retryNumber", retryNumber);
  }
  url = url + "?" + urlSearchParams.toString();
  let postResult = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": process.env.REACT_APP_API_KEY,
      "X-Captcha-Token": captchaToken,
    },
    body: JSON.stringify(results),
  });
  if (postResult.status === 200) {
    let postResultBody = await postResult.json();
    if (
      postResultBody.participantIdentifier &&
      postResultBody.participantSecret
    ) {
      window.localStorage.setItem(
        PARTICIPANT_IDENTIFIER_KEY,
        postResultBody.participantIdentifier
      );
      window.localStorage.setItem(
        PARTICIPANT_SECRET_KEY,
        postResultBody.participantSecret
      );
    }
    return postResultBody;
  } else {
    await handleError(postResult);
  }
}

async function updateResultsInternal(results, resultKey, captchaToken, retryNumber) {
  results.participantIdentifier = window.localStorage.getItem(
    PARTICIPANT_IDENTIFIER_KEY
  );
  results.participantSecret = window.localStorage.getItem(
    PARTICIPANT_SECRET_KEY
  );

  let url = `${process.env.REACT_APP_API_BASE_URL}/results/${resultKey}`;
  var urlSearchParams = new URLSearchParams();
  if (retryNumber) {
    urlSearchParams.append("retryNumber", retryNumber);
  }
  url = url + "?" + urlSearchParams.toString();
  let updateResult = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": process.env.REACT_APP_API_KEY,
      "X-Captcha-Token": captchaToken,
    },
    body: JSON.stringify(results),
  });
  if (updateResult.status === 200) {
    let updateResultBody = await updateResult.json();
    return updateResultBody;
  } else {
    await handleError(updateResult);
  }
}

async function handleError(postResult) {
  if (postResult.status === 400) {
    throw new Error(ERROR_400);
  } else if (postResult.status === 401) {
    const body = await postResult.json();
    if (body.retry) {
      throw new Error(ERROR_401_RETRY);
    } else {
      throw new Error(ERROR_401);
    }
  } else if (postResult.status === 500) {
    throw new Error(ERROR_500);
  }
  throw new Error(ERROR_500);
}


function generateCaptchaToken(action) {
  return new Promise((resolve, reject) => {
    window.grecaptcha.enterprise.ready(async () => {
      let token = await window.grecaptcha.enterprise.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        { action }
      );
      resolve(token);
    });
  });
}
