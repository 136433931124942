import React from "react";
import whitelogo from "../images/mmtc-logo-white.svg";
import nibib from "../images/NIH_NIBIB_Master_Logo_White.png";
import ce from "../images/ce-logo.svg";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "../theme";

export default function Footer() {
  const theme = useThemeContext();
  const { t } = useTranslation();

  return (
    <footer className="site-footer">
      <div className="container">
        <div>
          {theme.isWhiteLabeled ? (
            <p className="powered-by">Powered By:</p>
          ) : null}
          <img
            className="mmtc-logo"
            src={whitelogo}
            alt="Make My Test Count logo"
          />
        </div>
        <div className="funding">
          <p>{t("footer-about")}</p>
          <div className="logos">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.nibib.nih.gov/"
            >
              <img
                src={nibib}
                alt="National Institute of Biomedical Imaging and Bioengineering"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://careevolution.com/"
            >
              <img src={ce} alt="CareEvolution" />
            </a>
          </div>
          <p>{t("footer-funding")}</p>
        </div>
        <div className="disclaimer">
          <span dangerouslySetInnerHTML={{ __html: t("footer-disclaimer") }} />
          <span
            dangerouslySetInnerHTML={{ __html: t("footer-accessibility") }}
          />
        </div>
        {!theme.isWhiteLabeled ? (
          <div>
            <a
              href="mailto:info@makemytestcount.org"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fas fa-envelope"
                title="Email us"
                aria-label="Email Us"
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100087566936871"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-brands fa-facebook"
                title="Facebook"
                aria-label="Facebook"
              ></i>
            </a>
            <a
              href="https://www.linkedin.com/company/makemytestcount/"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-brands fa-linkedin"
                title="LinkedIn"
                aria-label="LinkedIn"
              ></i>
            </a>
            <a
              href="https://www.instagram.com/makemytestcount/"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-brands fa-instagram"
                title="Instagram"
                aria-label="Instagram"
              ></i>
            </a>
            <a
              href="https://x.com/makemytestcount"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-brands fa-x-twitter"
                title="x"
                aria-label="x"
              ></i>
            </a>
          </div>
        ) : null}
        <div>
          <span>© {new Date().getFullYear()} CareEvolution, LLC.</span> |{" "}
          <span
            dangerouslySetInnerHTML={{ __html: t("privacy-policy") }}
          ></span>
        </div>
      </div>
    </footer>
  );
}
