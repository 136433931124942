import React from "react";
import { find } from "lodash";
import PropTypes from "prop-types";
import brands from "./brands.json";
import useSessionStorage from "./helpers/useSessionStorage";

export const brandsWhitelist = brands.map(({ brand }) => brand);

const ThemeContext = React.createContext({});

export function ThemeContextProvider(props) {
  const [storedBrand, setStoredBrand] = useSessionStorage("mmtch-brand");

  const search = new URLSearchParams(window.location.search);
  const isEmbedded = search.get("isEmbedded") ? true : false;
  let theme = {
    // Tracking information for where the user came from (will be supplied for whitelabel users, otherwise is the current origin)
    origin: window.location.origin,
    isEmbedded,
    themeTestTypes: [],
  };

  let brandContent;
  // Check if a stored brand is present and it was saved in the past 48 hours
  if (storedBrand) {
    const storedContent = find(
      brands,
      (b) => b.brand.toLowerCase() === storedBrand
    );
    if (storedContent) {
      brandContent = storedContent;
    }
  } else {
    // Always lowercase the URL segments to ensure we can match them
    const urlSegments = window.location.pathname.toLowerCase().split("/");
    // The "brand" parameter is an identifier that is added to the form submit to help track whitelabeled usage of the site
    let brandIndex = -1;
    for (let i = 0; i < brandsWhitelist.length; i++) {
      const whiteListedBrand = brandsWhitelist[i].toLowerCase();
      brandIndex = urlSegments.indexOf(whiteListedBrand);
      if (brandIndex > -1) {
        break;
      }
    }
    if (brandIndex > -1) {
      const brand = urlSegments[brandIndex];
      brandContent = find(brands, (b) => b.brand.toLowerCase() === brand);
      setStoredBrand(brand);
    }
  }

  if (brandContent) {
    // Information for the health department section of the summary page
    theme.brand = brandContent.brand;
    theme.themeName = brandContent.name;
    theme.shouldShowName = brandContent.shouldShowName;
    theme.shouldShowResources = brandContent.shouldShowResources;
    theme.origin = brandContent.origin;
    theme.themeHomeUrl = brandContent.homeUrl;
    theme.themePositiveUrl = brandContent.positiveUrl || brandContent.homeUrl;
    theme.themeNegativeUrl = brandContent.negativeUrl || brandContent.homeUrl;
    theme.themeGeneralUrl = brandContent.generalUrl || brandContent.homeUrl;
    theme.themeLogoUrl = brandContent.logoUrl;
    theme.themeTestTypes = brandContent.testTypes;
    theme.isWhiteLabeled = true;
  }

  const currentClasses = document.documentElement.getAttribute("class") || "";
  let newClasses = currentClasses;
  if (theme.isEmbedded) {
    if (!currentClasses.includes("embedded")) {
      newClasses += " embedded";
    }
  }
  if (theme.isWhiteLabeled) {
    if (!currentClasses.includes("white-labeled")) {
      newClasses += " white-labeled";
    }
  }
  document.documentElement.setAttribute("class", newClasses);

  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  );
}

ThemeContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useThemeContext = () => React.useContext(ThemeContext);
